import React, {useEffect, useState} from "react";
import api_roliste from "../../../services/api_roliste";
import requests from "../../../services/requests";
import functions from "../../../services/functions";
import "../../../styles/CharacterResume.css"

const GmPlayerCharacterManagement = ({jwt, adventureId, adventureObject}) => {




    return (
        <div >

        </div>
    )
}

export default GmPlayerCharacterManagement;