import React from "react";

import "../styles/footer.css"

const Footer = () => {


    return (
        <div className="footer-container">
            <p> Julien POIRIER | 2021</p>
        </div>
    )
}

export default Footer;