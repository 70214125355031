
const baseUrl = "https://api-roliste-secure.julienpoirier-webdev.com/api/";

const requests = {
    requestDataActions: `${baseUrl}player_characters/gm`,
    requestMessagesAdventure: `${baseUrl}adventures/message`,
    requestDataAdventure: `${baseUrl}adventures/data`,
    requestVisitedPlaceByAdventure: `${baseUrl}visited_places/adventure`,
    requestItemInBagByAdventure: `${baseUrl}item_in_bags/adventure`,
    requestEquipmentByAdventure: `${baseUrl}equipment/adventure`,
    requestNpcOnAdventureByAdventure: `${baseUrl}npc_on_adventures/adventure`,
    requestCharactersByAdventure: `${baseUrl}player_characters/adventure`,
    requestCheckUserAsPlayerCharacterInAdventure:`${baseUrl}player_characters/check`,
    requestLogin:`${baseUrl}login`,
    requestHash:`${baseUrl}hash`,
    requestAdventureConnection:  `${baseUrl}adventures/connexion`,
    requestAdventureByUser: `${baseUrl}adventures/user`,
    requestAdventureCount: `${baseUrl}adventures/count`,
    requestAdventureCheck:`${baseUrl}adventures/check`,
    requestAdventureId:`${baseUrl}adventures/id`,
    requestCheck:`${baseUrl}check`,
    requestAdventureCard: `${baseUrl}adventure_cards`,
    requestAdventure: `${baseUrl}adventures`,
    requestAvatarIcon: `${baseUrl}avatar_icons`,
    requestCaracteristicType: `${baseUrl}caracteristic_types`,
    requestCaracteristic: `${baseUrl}caracteristics`,
    requestCountry: `${baseUrl}countries`,
    requestDiceThrow: `${baseUrl}dice_throws`,
    requestDice: `${baseUrl}dices`,
    requestDocument: `${baseUrl}documents`,
    requestEquipment: `${baseUrl}equipment`,
    requestEquipmentType: `${baseUrl}equipment_types`,
    requestFrontierBetweenPlaces: `${baseUrl}frontier_between_places`,
    requestGoalInAdventure: `${baseUrl}goal_in_adventures`,
    requestGoalType: `${baseUrl}goal_types`,
    requestGoal: `${baseUrl}goals`,
    requestItemInAction: `${baseUrl}item_in_actions`,
    requestItemInBag: `${baseUrl}item_in_bags`,
    requestItem: `${baseUrl}items`,
    requestLastUpdateItem: `${baseUrl}last_update_items`,
    requestLastUpdateType: `${baseUrl}last_update_types`,
    requestUser: `${baseUrl}users`,
    requestMessageInChat: `${baseUrl}message_in_chats`,
    requestNpcJob: `${baseUrl}npc_jobs`,
    requestNpcOnAdventure: `${baseUrl}npc_on_adventures`,
    requestNpcStrength: `${baseUrl}npc_strengths`,
    requestNpc: `${baseUrl}npcs`,
    requestPlaceType: `${baseUrl}place_types`,
    requestPlace: `${baseUrl}places`,
    requestPlayerCharacter: `${baseUrl}player_characters`,
    requestPowerDeveloped: `${baseUrl}power_developeds`,
    requestPowerInAction: `${baseUrl}power_in_actions`,
    requestPower: `${baseUrl}powers`,
    requestQuestInAdventure: `${baseUrl}quest_in_adventures`,
    requestQuest: `${baseUrl}quests`,
    requestRace: `${baseUrl}races`,
    requestRegion: `${baseUrl}regions`,
    requestSkillDeveloped: `${baseUrl}skill_developeds`,
    requestSkillInAction: `${baseUrl}skill_in_actions`,
    requestSkill: `${baseUrl}skills`,
    requestSpellInAction: `${baseUrl}spell_in_actions`,
    requestSpell: `${baseUrl}spells`,
    requestVisitedPlace: `${baseUrl}visited_places`,
    requestZone: `${baseUrl}zones`
};

export default requests;
