import React from "react";

import "../styles/title.css";

const Title = () => {
    return (
        <div className={"title-container"}>
        <h1 className={"title"}>Roliste</h1>
        </div>
    )
}

export default Title;